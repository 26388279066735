/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, InputGroup } from 'react-bootstrap';
import { InputGroupPrepend, FormControl } from 'styles/ui';
import { trim, size } from 'lodash';
import { FaPhone } from 'react-icons/fa';
import InputMask from 'react-input-mask';

const FormInputMask = ({
  name,
  mask,
  values,
  isOptinal,
  label,
  touched,
  errors,
  handleChange,
  isGroupPrepend,
  ...restProps
}) => {
  const [maskState, setMask] = useState(mask || '(99) 99999-9999');

  const handleOnBlur = (e) => {
    if (name === 'phone' && size(trim(e.target.value, '_')) === 14) {
      setMask('(99) 9999-9999');
    }
  };

  const handleOnFocus = (e) => {
    if (name === 'phone' && size(trim(e.target.value, '_')) === 14) {
      setMask('(99) 99999-9999');
    }
  };

  return (
    <Form.Group controlId={`formBasic${name}`}>
      <Form.Label className="font-weight-bold">
        {label}
        {isOptinal && <small className="text-muted">(opcional)</small>}
      </Form.Label>
      <InputGroup>
        {isGroupPrepend && (
          <InputGroupPrepend>
            <InputGroup.Text>
              <FaPhone size={20} />
            </InputGroup.Text>
          </InputGroupPrepend>
        )}
        <InputMask
          mask={maskState}
          name={name}
          value={values[name]}
          onChange={handleChange}
          isInvalid={errors[name] && touched[name] && errors[name]}
          onBlur={handleOnBlur}
          onFocus={handleOnFocus}
          {...restProps}>
          {(inputProps) => <FormControl {...inputProps} />}
        </InputMask>
      </InputGroup>
      <Form.Control.Feedback type="invalid">
        {errors[name] && touched[name] && errors[name]}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

FormInputMask.propTypes = {
  isGroupPrepend: PropTypes.bool,
  name: PropTypes.string,
  mask: PropTypes.string,
  isOptinal: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  values: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object),
  touched: PropTypes.instanceOf(Object),
  handleChange: PropTypes.func,
};

export default FormInputMask;
