import { gql } from 'apollo-boost';

export const GET_USER = gql`
  query getUser($id: ID!) {
    user(id: $id) {
      id
      name
      email
      company {
        id
        name
        slug
        email
        phone
        site
        description
        instagram
        whatsapp {
          __typename
          ... on ComponentCompanyWhatsapp {
            id
            cellphone
            link
          }
        }
        categories {
          id
          name
        }
        description
        picture {
          id
          name
          name
          url
          size
        }
        address
        city {
          id
          name
        }
        neighborhood
        postalCode
        state
        stateSort
        deliveryType
        deliveryTime
        deliveryPrice
      }
      updatedAt
      createdAt
      confirmed
      blocked
    }
  }
`;

export const GET_COMPANIES_BY_CITY = gql`
  query getCompanyBySlug($where: JSON) {
    companies(where: $where) {
      id
      name
      slug
      email
      phone
      site
      instagram
      whatsapp {
        __typename
        ... on ComponentCompanyWhatsapp {
          id
          cellphone
          link
        }
      }
      categories {
        id
        name
      }
      description
      picture {
        id
        name
        name
        url
        size
      }
      address
      city {
        id
        name
        state {
          name
          code
        }
      }
      neighborhood
      postalCode
      state
      stateSort
      deliveryType
      deliveryTime
      deliveryTime
    }
  }
`;

export const GET_COMPANIES = gql`
  query getCompanies($sort: String, $limit: Int, $start: Int, $where: JSON) {
    cities(sort: "name") {
      id
      name
      alias
      state {
        code
      }
      companies {
        slug
      }
    }
    companies(sort: $sort, limit: $limit, start: $start, where: $where) {
      id
      name
      slug
      instagram
      categories {
        id
        name
      }
      picture {
        url
      }
      address
      city {
        id
        name
      }
      neighborhood
      postalCode
      state
      stateSort
      deliveryType
      deliveryTime
      deliveryPrice
    }
  }
`;

export const GET_COMPANY_BY_SLUG = gql`
  query getCompanyBySlug($slug: String) {
    companyBySlug(slug: $slug) {
      id
      name
      slug
      email
      phone
      site
      description
      instagram
      whatsapp {
        __typename
        ... on ComponentCompanyWhatsapp {
          id
          cellphone
          link
        }
      }
      categories {
        id
        name
      }
      picture {
        url
      }
      city {
        id
        name
        state {
          name
          code
        }
      }
      address
      neighborhood
      postalCode
      deliveryType
      deliveryTime
      deliveryPrice
    }
  }
`;
