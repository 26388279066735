import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { cityToSlug } from 'helpers';
import { ImageProgressive } from 'components';
import {
  CardHeader,
  CardCompanyName,
  CardFigure,
  CardTitle,
  CardInfo,
  CardCompany,
  // CardStatus,
  CardFooter,
} from './styles';

const legendDelivery = {
  free: 'Grátis',
  area: 'à calcular',
};

const CompanyCard = ({ company }) => {
  const {
    picture,
    slug,
    name,
    categories,
    neighborhood,
    city,
    deliveryTime,
    deliveryType,
    deliveryPrice,
  } = company;

  return (
    <CardCompany href={`/delivery/${cityToSlug(city)}/${slug}`} title={name}>
      <CardFigure>
        {/* <CardStatus>Fechado</CardStatus> */}
        <ImageProgressive
          src={picture ? picture.url : '/image/no_image.jpg'}
          rounded
          className="img-fluid"
        />
      </CardFigure>
      <CardTitle>
        <CardHeader>
          <CardCompanyName>{name}</CardCompanyName>
        </CardHeader>
        <CardInfo className="align-items-center">
          <span className="text-yellow category">{categories[0].name}</span>
          <span className="px-1">•</span>
          {neighborhood} <span className="px-1">•</span> {city.name}
        </CardInfo>
        <CardInfo>
          <small className="text-muted">@{slug}</small>
        </CardInfo>
      </CardTitle>
      <CardFooter>
        <span>
          {deliveryTime.min}-{deliveryTime.max} min •{' '}
          <span
            className={classnames('font-weight-bolder', {
              'text-success': deliveryType.includes('free'),
            })}>
            Entrega {legendDelivery[deliveryType] ? legendDelivery[deliveryType] : deliveryPrice}
          </span>
        </span>
      </CardFooter>
    </CardCompany>
  );
};

CompanyCard.propTypes = {
  company: PropTypes.shape({
    name: PropTypes.string,
    slug: PropTypes.string,
    instagram: PropTypes.string,
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      })
    ),
    picture: PropTypes.shape({
      url: PropTypes.string,
    }),
    address: PropTypes.string,
    city: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
    neighborhood: PropTypes.string,
    postalCode: PropTypes.string,
    state: PropTypes.string,
    stateSort: PropTypes.string,
    deliveryType: PropTypes.string,
    deliveryTime: PropTypes.shape({
      min: PropTypes.string,
      max: PropTypes.string,
    }),
    deliveryPrice: PropTypes.string,
  }),
};

export default CompanyCard;
