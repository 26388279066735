const Objeto_delivery = {
  free: 'Entrega Grátis',
  fixed: 'Entrega fixa',
  area: 'Entrega por bairro',
};
export const getDeliveryTypes = (type) => {
  if (Objeto_delivery[type]) return Objeto_delivery[type];
  return '';
};

export default [
  {
    label: 'Entrega Grátis',
    value: 'free',
  },
  {
    label: 'Entrega fixa',
    value: 'fixed',
  },
  {
    label: 'Entrega por bairro',
    value: 'area',
  },
  // {
  //   label: 'Entrega por distância',
  //   value: 'distance',
  // },
];
