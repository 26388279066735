export const MESSAGES_API = {
  'Auth.form.error.code.provider': 'Código incorreto fornecido.',
  'Auth.form.error.email.invalid': 'Este email é inválido.',
  'Auth.form.error.email.provide': 'Por favor, forneça seu nome de usuário ou seu e-mail.',
  'Auth.form.error.email.taken': 'O email já foi usado por outra conta',
  'Auth.form.error.invalid': 'E-mail ou senha inválida.',
  'Auth.form.error.noAdminAccess': 'Você não pode acessar o painel de administração.',
  'Auth.form.error.params.provide': 'Params incorretos fornecidos.',
  'Auth.form.error.password.format': 'Sua senha não pode conter o símbolo` $ `mais de três vezes.',
  'Auth.form.error.password.local':
    'Este usuário nunca definiu uma senha local, por favor faça o login através do provedor usado durante a criação da conta.',
  'Auth.form.error.password.matching': 'As senhas não coincidem.',
  'Auth.form.error.password.provide': 'Por favor, forneça sua senha',
  'Auth.form.error.user.not-exist': 'Este e-mail não existe.',
  'Auth.form.error.username.taken': 'Nome de usuário já foi obtido',
  'ConfigPage.description': 'Configure a extensão para envio de arquivos',
  'ConfigPage.title': 'Enviar Arquivos - Configurações',
  'EditForm.Input.number.label': 'Tamanho máximo permitido (em MB)',
  'EditForm.Input.select.inputDescription':
    'Arquivos podem ser transferidos em seu servidor ou num provedor externo.',
  'EditForm.Input.select.label': 'Provedores',
  'EditForm.Input.toggle.label': 'Ativar o envio de arquivos',
  'EmptyLi.message': 'Nenhum arquivo enviado',
  'EntriesNumber.number': '{number} arquivo encontrado',
  'EntriesNumber.number.plural': '{number} arquivos encontrados',
  'HomePage.InputSearch.placeholder': 'Buscar arquivo...',
  'HomePage.description': 'Ver todos os arquivos enviados',
  'HomePage.title': 'Enviar Arquivos',
  'Li.linkCopied': 'Link copiado para a área de transferência',
  'ListHeader.hash': 'Hash',
  'ListHeader.name': 'Nome',
  'ListHeader.related': 'Relacionado à',
  'ListHeader.size': 'Tamanho',
  'ListHeader.type': 'Tipo',
  'ListHeader.updated': 'Atualizado',
  'PluginInputFile.link': 'selecione',
  'PluginInputFile.loading': 'Seus arquivos estão sendo enviados...',
  'PluginInputFile.text':
    'Arraste & solte os seus arquivos nesta area ou {link} um arquivo para enviar',
  'notification.config.success': 'As configurações foram atualizadas',
  'notification.delete.success': 'O arquivo foi removido',
  'notification.dropFile.success': 'Seu arquivo foi enviado com sucesso',
  'notification.dropFiles.success': '{number} arquivos foram enviados com sucesso',
  'attribute.WYSIWYG': 'Texto (WYSIWYG)',
  'attribute.boolean': 'Booleano',
  'attribute.date': 'Data',
  'attribute.decimal': 'Decimal',
  'attribute.email': 'Email',
  'attribute.enumeration': 'Enumeração',
  'attribute.float': 'Float',
  'attribute.integer': 'Inteiro',
  'attribute.json': 'JSON',
  'attribute.media': 'Mídia',
  'attribute.password': 'Senha',
  'attribute.relation': 'Relação',
  'attribute.string': 'String',
  'attribute.text': 'Texto',
  'button.attributes.add': 'Adicionar Novo Campo',
  'button.contentType.add': 'Adicionar Tipo de Conteúdo',
  'button.contentType.create': 'Criar Tipo de Conteúdo',
  'contentType.temporaryDisplay': '(Não salvo)',
  'error.attribute.forbidden': 'Este nome de atributo está reservado',
  'error.attribute.key.taken': 'Este valor já existe',
  'error.attribute.sameKeyAndName': 'Não pode ser igual',
  'error.attribute.taken': 'O nome deste campo já existe',
  'error.contentTypeName.taken': 'Este nome já existe',
  'error.validation.max': 'O valor é muito alto.',
  'error.validation.maxLength': 'O valor é muito longo.',
  'error.validation.min': 'O valor é muito baixo.',
  'error.validation.minLength': 'O valor é muito curto.',
  'error.validation.minSupMax': 'Não pode ser superior',
  'error.validation.regex': 'O valor não corresponde ao regex The value does not match the regex.',
  'error.validation.required': 'Este valor deste registro é obrigatório.',
  'error.model.fetch': 'Ocorreu um erro durante a configuração dos modelos de busca.',
  'error.record.create': 'Ocorreu um erro durante a criação de registro.',
  'error.record.delete': 'Ocorreu um erro durante a remoção do registro.',
  'error.record.fetch': 'Ocorreu um erro durante o registro de busca.',
  'error.record.update': 'Ocorreu um erro durante a atualização do registro.',
  'error.records.count': 'Ocorreu um erro durante a contagem de registros da buscar.',
  'error.records.fetch': 'Ocorreu um erro durante os registros de busca.',
  'error.schema.generation': 'Ocorreu um erro durante a geração dos esquemas.',
  'error.validation.json': 'Isto não corresponde com o formato JSON',

  'Error.custom': 'Ocorreu um erro, tente novamente',
  'Form.custom': 'Verifique todos os campos e tente novamente',
};
