import { useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_USER } from 'apollo/queries';
import store from 'store/store';
import { MUTATION_UPDATE_COMPANY } from 'apollo/mutations';

export const useGetUser = ({ dispatch }) => {
  const { user: userStoge = {} } = store.getState().userModel;
  const { loading, data, error, refetch, networkStatus } = useQuery(GET_USER, {
    variables: { id: userStoge.id },
  });
  useEffect(() => {
    if (!loading && !error && data && data.user) {
      dispatch.userModel.updateUser({ user: data.user });
    }
  }, [loading, data, error, dispatch]);
  return {
    data,
    loading,
    error,
    refetch,
    networkStatus,
  };
};

export const useCompanyUpdate = () => {
  const updateCache = (cache, { data: dataMutation }) => {
    cache.writeQuery({
      query: GET_USER,
      data: { company: dataMutation },
    });
  };
  const [updateCompany, optionsMutation] = useMutation(MUTATION_UPDATE_COMPANY, {
    update: updateCache,
  });
  return {
    updateCompany,
    ...optionsMutation,
  };
};
