import styled from 'styled-components';
import { Form, InputGroup } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input';
import { Link } from 'react-router-dom';

export const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 12px;

  @media only screen and (min-width: 572px) {
    grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
  }

  @media only screen and (min-width: 992px) {
    width: 100%;
    max-width: 1366px !important;
    margin: auto;import { Link } from 'react-router-dom';

  }
`;

export const FormControl = styled(Form.Control)`
  display: flex;
  min-height: 50px;
  height: ${(props) => (props.as && props.as === 'textarea' ? 'auto' : '50px')};
  width: 100%;
  border: 1px solid #e5e5e5;
  font-size: 1rem;
  line-height: 1.25em;
  font-weight: 300;
  /* background: #f7f7f7; */
  border-radius: 4px;
  overflow: visible;
  color: #242424;
  outline: 0;
  padding: 0.375rem 0.75rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  ${(props) =>
    props.as &&
    props.as === 'textarea' &&
    props.isInvalid &&
    `
    border: 1px solid #dc3545;
    border-radius: 4px;
    background-image: url(data:image/svg+xml,%3csvg xmlns=http://www.w3.org/2000/svg width=12 hei…circle cx=6 cy=8.2 r=.6 fill=%23dc3545 stroke=none/%3e%3c/svg%3e);
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  `}

  &:focus {
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
    ${(props) =>
      props.value &&
      `color: #646464;
      font-weight: 500;
    `}
  }
`;

export const FormCurrencyInput = styled(CurrencyInput).attrs({
  className: 'form-control',
})`
  display: flex;
  min-height: 50px;
  height: ${(props) => (props.as && props.as === 'textarea' ? 'auto' : '50px')};
  width: 100%;
  border: 1px solid #e5e5e5;
  font-size: 1rem;
  line-height: 1.25em;
  /* background: #f7f7f7; */
  border-radius: 4px;
  overflow: visible;
  color: #242424;
  outline: 0;
  padding: 0.375rem 0.75rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  ${(props) =>
    props.as &&
    props.as === 'textarea' &&
    props.isInvalid &&
    `
    border: 1px solid #dc3545;
    border-radius: 4px;
    background-image: url(data:image/svg+xml,%3csvg xmlns=http://www.w3.org/2000/svg width=12 hei…circle cx=6 cy=8.2 r=.6 fill=%23dc3545 stroke=none/%3e%3c/svg%3e);
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  `}
  &:focus {
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
    ${(props) =>
      props.value &&
      `color: #646464;
      font-weight: 500;
    `}
  }
`;

export const InputGroupPrepend = styled(InputGroup.Prepend)`
  .input-group-text {
    font-size: 0.8rem;
    border: 1px solid #f2f2f2;
    font-weight: bold;
    line-height: 1.25em;
  }
`;

export const LoadingOverlay = styled.div`
  display: ${(props) => (!props.visible ? 'block' : 'none')};
  background: #2a2a2a55;
  color: ${(props) => props.color || '#000'};
  text-align: center;
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: hidden;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  &:before {
    content: '';
    display: block;
    position: fixed;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
  }
`;

export const ButtonRouterLink = styled(Link)`
  width: inherit;
  padding: 0;
  cursor: pointer;
  display: flex;
  margin: 0;
  background: transparent;
  color: ${(props) => (props.color ? props.color : '#ef4a37')};
  border: none;
  overflow: hidden;
  font-weight: 500;
  transition: 100ms;
  border-radius: 4px;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: ${(props) => (props.color ? props.color : '#ef4a37')};
    span {
      color: ${(props) => (props.color ? props.color : '#ef4a37')};
    }
  }

  &:active {
    opacity: 0.8;
  }

  &:focus {
    outline: transparent;
  }

  span {
    font-size: 1rem;
    color: #3e3e3e;
    font-weight: 300;
  }
  @media only screen and (min-width: 572px) {
    span {
      font-size: 0.85rem;
      color: #3e3e3e;
      font-weight: 300;
    }
  }
`;

export const ButtonLoadMore = styled.button`
  width: 100%;
  background: transparent;
  border: 1px solid #eaeaea;
  font-size: 1rem;
  font-weight: 500;
  -webkit-appearance: button;
  color: #ef4a37;
  text-decoration: none;
  padding: 0 20px;
  border-radius: 4px;
  margin: 3px 0;
  height: 50px;
  transition: 100ms;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  &:hover {
    color: #fff;
    background: #ef4a37;
  }

  &:active {
    opacity: 0.8;
  }

  &:focus {
    outline: transparent;
  }
`;
