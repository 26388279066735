import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Alert } from 'react-bootstrap';

const AlertMessages = ({ error, messages, scrollTop }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    const windoScrollTo = () => {
      window.scrollTo(0, 0);
    };
    if (scrollTop) windoScrollTo();
  }, [error, scrollTop]);
  function handleOnClose() {
    dispatch.registerModel.hideAlert();
  }
  if (!error) return null;
  return (
    <Alert variant="danger" onClose={handleOnClose} dismissible className="py-4 mt-3">
      <Alert.Heading>Ops, aconteceu alguma coisa errada :/</Alert.Heading>
      <hr />
      <ul>
        {messages.map((it) => (
          <li key={it} className="text-left t-16 m-0 d-block">
            {it.message}
          </li>
        ))}
      </ul>
    </Alert>
  );
};

AlertMessages.propTypes = {
  error: PropTypes.bool,
  scrollTop: PropTypes.bool,
  messages: PropTypes.instanceOf(Array),
};

export default AlertMessages;
