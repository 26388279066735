/* eslint-disable react/prop-types */
import React from 'react';
import Image from 'assets/img/undraw_alone.svg';
import { Link } from 'react-router-dom';
import { Row, Col, Container } from 'react-bootstrap';
import { Header, Footer } from 'components';

const CityNotFound = ({ hideHeader = false, hideFooter = false }) => (
  <>
    {!hideHeader && <Header />}
    <Container className="my-5">
      <Row className="py-4">
        <Col xs={12} lg={6} className="text-left">
          <h1 className="font-weight-bold pb-2 text-danger" style={{ fontSize: '2.5em' }}>
            Cidade Não encontrada!
          </h1>
          <Link to="/" className="btn btn-outline-dark font-weight-bold ">
            Página Inicial
          </Link>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col lg={{ offset: 3, span: 6 }} className="text-center">
          <img src={Image} title="Logo" alt="Logo Marca" className="img-fluid" />
        </Col>
      </Row>
    </Container>
    {!hideFooter && <Footer />}
  </>
);

export default CityNotFound;
