import React from 'react';
import Image from 'assets/img/undraw_server_down.svg';
import { Row, Col, Container, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

const PageError = ({ onClick, networkStatus }) => (
  <>
    <div className="py-5 d-flex flex-column justify-content-center align-items-center">
      <Container>
        <Row className="flex-column-reverse flex-lg-row-reverse">
          <Col lg={6} className="text-center">
            <img src={Image} title="Logo" alt="Logo Marca" className="img-fluid" />
          </Col>
          <Col xs={12} lg={6} className="text-left">
            <h1 className="font-weight-bold" style={{ fontSize: '3em' }}>
              Oops! :/
            </h1>
            <h5 className="text-muted mb-4">Algo inesperado aconteceu.</h5>
            {networkStatus && networkStatus <= 4 ? (
              <Button onClick={onClick} variant="outline-danger" className="font-weight-bold ">
                Tentar novamente
              </Button>
            ) : (
              <p className="text-muted mb-4">Atualize a página ou entre com contato.</p>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  </>
);
PageError.propTypes = {
  onClick: PropTypes.func,
  networkStatus: PropTypes.number,
};

export default PageError;
