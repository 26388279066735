import { isArray } from 'lodash';

export const formartSelectValues = (values) => {
  const newValues = Object.keys(values).reduce((il, it) => {
    if (isArray(values[it])) {
      return {
        ...il,
        [it]: values[it].map((obj) => obj.value),
      };
    }

    return {
      ...il,
      [it]: values[it].value,
    };
  }, []);
  return newValues;
};
