/* eslint-disable no-async-promise-executor */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { InputFormGroup } from 'components';
import { Row, Col } from 'react-bootstrap';
import { WiTime2, WiTime3 } from 'react-icons/wi';

const DeliveryTime = ({ values, errors, touched, handleChange }) => {
  return (
    <>
      <p className="font-weight-bold">
        Tempo estimado da entrega<small>(média em minutos).</small>
      </p>
      <Row>
        <Col xs={6}>
          <InputFormGroup
            label="No mínimo"
            name="deliveryTime.min"
            values={values}
            errors={errors}
            touched={touched}
            onChange={handleChange}
            disableFocus
            maxLength={3}
            placeholder="20"
            prependComponent={<WiTime2 size={20} />}
          />
        </Col>
        <Col xs={6}>
          <InputFormGroup
            label="No máximo"
            name="deliveryTime.max"
            values={values}
            errors={errors}
            touched={touched}
            onChange={handleChange}
            disableFocus
            maxLength={3}
            placeholder="40"
            prependComponent={<WiTime3 size={20} />}
          />
        </Col>
      </Row>
    </>
  );
};

DeliveryTime.propTypes = {
  values: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object),
  touched: PropTypes.instanceOf(Object),
  handleChange: PropTypes.func,
};

export default DeliveryTime;
