/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { FormInputMaskMoney } from 'components';

const FromInputPrice = ({ values, errors, touched, handleChange, setValues }) => {
  return (
    <FormInputMaskMoney
      label="Taxa de entrega R$"
      disabled={
        !values.deliveryType ||
        (values.deliveryType && ['free', 'area'].includes(values.deliveryType.value))
      }
      name="deliveryPrice"
      {...{ values, errors, touched, handleChange, setValues }}
    />
  );
};

FromInputPrice.propTypes = {
  values: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object),
  touched: PropTypes.instanceOf(Object),
  handleChange: PropTypes.func,
  setValues: PropTypes.func,
};

export default FromInputPrice;
