import { gql } from 'apollo-boost';

export const MUTATION_UPDATE_COMPANY = gql`
  mutation UpdateCompany($where: InputID, $data: editCompanyInput) {
    updateCompany(input: { where: $where, data: $data }) {
      company {
        id
        name
        slug
        email
        phone
        site
        description
        instagram
        whatsapp {
          __typename
          ... on ComponentCompanyWhatsapp {
            id
            cellphone
            link
          }
        }
        categories {
          id
          name
        }
        description
        picture {
          id
          name
          name
          url
          size
        }
        address
        city {
          id
          name
        }
        neighborhood
        postalCode
        state
        stateSort
        deliveryType
        deliveryTime
        deliveryPrice
      }
    }
  }
`;
