import { useQuery } from '@apollo/react-hooks';
import { GET_COMPANIES } from 'apollo/queries';

export const useListCompanies = (variables = { limit: 1 }) => {
  const response = useQuery(GET_COMPANIES, {
    variables,
    fetchPolicy: 'no-cache',
  });

  return response;
};
