import { useQuery } from '@apollo/react-hooks';
import { GET_COMPANIES_BY_CITY } from 'apollo/queries';

export const useCompaniesByCity = ({ alias }) => {
  const response = useQuery(GET_COMPANIES_BY_CITY, {
    variables: {
      where: {
        city: { alias },
      },
    },
  });
  return response;
};
