import React from 'react';
import Image from 'assets/img/undraw_dev_productivity.svg';
import { Link } from 'react-router-dom';
import { Row, Col, Container } from 'react-bootstrap';

const PageInDeveloper = () => (
  <>
    <div className="py-5 d-flex flex-column justify-content-center align-items-center">
      <Container>
        <Row className="flex-column-reverse flex-lg-row-reverse">
          <Col lg={6} className="text-center">
            <img src={Image} title="Logo" alt="Logo Marca" className="img-fluid" />
          </Col>
          <Col xs={12} lg={6} className="text-left">
            <h1 className="font-weight-bold" style={{ fontSize: '3em' }}>
              Oops! Desculpa,
            </h1>
            <h5 className="text-muted mb-4">Essa área do site está em desenvolvimento.</h5>
            <Link to="/" className="btn btn-outline-danger font-weight-bold ">
              Página Inicial
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  </>
);

export default PageInDeveloper;
