import React from 'react';
import PropTypes from 'prop-types';
import LogoMarca from 'assets/img/logo.svg';
import ReactLoading from 'react-loading';
import { Container, Content, Figure } from './styles';

const SuspenseLoading = ({ hideLogo }) => {
  return (
    <Container>
      <Content>
        <Figure>
          {!hideLogo ? (
            <img src={LogoMarca} width={100} height={100} title="Logo" alt="Logo Marca" />
          ) : null}
        </Figure>
        <ReactLoading type="bars" color="#ef4a37" />
      </Content>
    </Container>
  );
};

SuspenseLoading.defaultProps = {
  hideLogo: true,
};

SuspenseLoading.propTypes = {
  hideLogo: PropTypes.bool,
};

export default SuspenseLoading;
