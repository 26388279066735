import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactLoading from 'react-loading';
import { Overlay, MessageTitle, Content } from './styles';

const LoadingOverlay = ({ type, visible, message, scrollTop }) => {
  function windoScrollTo() {
    window.scrollTo(0, 0);
  }
  useEffect(() => {
    if (visible) {
      document.querySelector('body').classList.add('overflow-hidden');
      if (scrollTop) windoScrollTo();
    } else document.querySelector('body').classList.remove('overflow-hidden');
    return () => {
      document.querySelector('body').classList.remove('overflow-hidden');
    };
  }, [visible, scrollTop]);

  return (
    <Overlay visible={visible}>
      <Content>
        <ReactLoading type={type} color="#fff" width={100} height={100} />
        <MessageTitle>{message}</MessageTitle>
      </Content>
    </Overlay>
  );
};

LoadingOverlay.defaultProps = {
  type: 'bars',
  visible: false,
  scrollTop: false,
  message: 'Aguarde...',
};

LoadingOverlay.propTypes = {
  type: PropTypes.string,
  scrollTop: PropTypes.bool,
  visible: PropTypes.bool,
  message: PropTypes.string,
};

LoadingOverlay.propTypes = {};

export default LoadingOverlay;
