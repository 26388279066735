import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const SectionFooter = styled.footer`
  border-top: 1px solid #dcdcdc;
  display: grid;
  grid-template-columns: auto;
  gap: 20px;
  padding: 40px 0;

  @media only screen and (min-width: 992px) {
    grid-auto-columns: -webkit-min-content;
    grid-auto-columns: min-content;
    grid-template-columns: repeat(auto-fit, minmax(10px, auto));
    gap: 16px;
  }
  @media only screen and (min-width: 772px) {
    gap: 30px;
  }
`;

export const ContentFooter = styled.footer`
  display: grid;
  align-items: center;
  grid-gap: 0 20px;
  grid-template-columns: 50px 1fr;
  grid-template-areas:
    'logo-link company-copy'
    'logo-link company-info';
`;

export const LogoFooter = styled(Link)`
  flex-basis: 64px;
  margin-right: 20px;
  min-width: 50px;
  grid-area: logo-link;
  background-color: transparent;
  span {
    display: inline-block;
    position: relative;
  }
  @media only screen and (min-width: 992px) {
    cursor: pointer;
  }
`;

export const FooterDescription = styled.p`
  grid-area: company-copy;
  width: 100%;
  font-size: 0.6875rem;
  line-height: 1.35;
  font-weight: 300;
  color: #717171;
  margin: 0;
  @media only screen and (min-width: 992px) {
    font-size: 0.8125rem;
  }
`;
export const FooterInfo = styled.a`
  grid-area: company-info;
  width: 100%;
  font-size: 0.6875rem;
  line-height: 1.35;
  font-weight: 300;
  color: #717171;
  margin: 0;
  &:hover {
    text-decoration: none;
    color: #ef4a37;
  }

  &:active {
    opacity: 0.8;
  }

  &:focus {
    outline: transparent;
  }
  @media only screen and (min-width: 992px) {
    font-size: 0.8125rem;
  }
`;

export const FooterLink = styled(Link)`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.22;
  color: #717171;
  transition: color 0.15s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  text-decoration: none;
  display: inline-block;
  width: 100%;
  margin: 10px 0;
  &:hover {
    text-decoration: none;
    color: #3e3e3e;
  }

  &:active {
    opacity: 0.8;
  }

  &:focus {
    outline: 0;
  }
  @media only screen and (min-width: 992px) {
    cursor: pointer;
  }
`;

export const FooterLinkExternal = styled.a`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.22;
  color: #717171;
  transition: color 0.15s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  text-decoration: none;
  display: inline-block;
  width: 100%;
  margin: 10px 0;
  &:hover {
    text-decoration: none;
    color: #3e3e3e;
  }

  &:active {
    opacity: 0.8;
  }

  &:focus {
    outline: 0;
  }
  @media only screen and (min-width: 992px) {
    cursor: pointer;
  }
`;
