import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import { getPersistor } from '@rematch/persist';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { SuspenseLoading } from 'components';
import { ApolloProvider } from '@apollo/react-hooks';
import client from 'apollo/client';
// import App from './App';
import store from './store/store';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-multi-carousel/lib/styles.css';
import 'sweetalert2/src/sweetalert2.scss';
import './assets/scss/index.scss';
import * as serviceWorker from './serviceWorker';

const App = React.lazy(() => import('./App'));
const persistor = getPersistor();

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Provider store={store}>
        <PersistGate persistor={persistor} loading={<SuspenseLoading hideLogo />}>
          <React.Suspense fallback={<SuspenseLoading hideLogo />}>
            <App />
          </React.Suspense>
        </PersistGate>
      </Provider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
