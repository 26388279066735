import styled from 'styled-components';

export const Overlay = styled.div`
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #2a2a2a55;
  color: ${(props) => props.color || '#000'};
  text-align: center;
  position: fixed;
  z-index: 999;
  overflow: hidden;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  &:before {
    content: '';
    display: block;
    position: fixed;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
  }
`;
export const Content = styled.div`
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 25px 50px 25px 50px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const MessageTitle = styled.p`
  font-weight: 500;
  color: #fff;
  font-size: 1.5rem;
`;
