/* eslint-disable react/prop-types */
import React from 'react';
import Image from 'assets/img/undraw_not_found.svg';
import { Link } from 'react-router-dom';
import { Row, Col, Container } from 'react-bootstrap';
import { Header, Footer } from 'components';

const PageNotFound = ({
  message = 'Endereço não encontrado',
  hideHeader = false,
  hideFooter = false,
}) => (
  <>
    {!hideHeader && <Header />}
    <div className="py-5 d-flex flex-column justify-content-center align-items-center">
      <Container>
        <Row className="flex-column-reverse flex-lg-row-reverse">
          <Col lg={6} className="text-center">
            <img src={Image} title="Logo" alt="Logo Marca" className="img-fluid" />
          </Col>
          <Col xs={12} lg={6} className="text-left">
            <h1 className="font-weight-bold" style={{ fontSize: '5em' }}>
              Oops!
            </h1>
            <h4 className="text-muted mb-4">{message} :/</h4>
            <Link to="/" className="btn btn-outline-danger font-weight-bold ">
              Página Inicial
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
    {!hideFooter && <Footer />}
  </>
);

export default PageNotFound;
