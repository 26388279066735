import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink } from 'apollo-link';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import store from 'store/store';

const { REACT_APP_URL_API, REACT_APP_LOCAL_STORAGE } = process.env;
// Observar error GraphQL

// eslint-disable-next-line consistent-return
const linkError = onError((data) => {
  const { networkError } = data;
  if (networkError && networkError.statusCode === 401) {
    store.dispatch.userModel.signOutError();
  }
});

const httpLink = createHttpLink({
  uri: `${REACT_APP_URL_API}/graphql`,
});

const authLink = setContext(async (_, { headers }) => {
  const token = localStorage.getItem(REACT_APP_LOCAL_STORAGE);
  return {
    headers: {
      ...headers,
      ...(token ? { authorization: `Bearer ${token}` } : {}),
    },
  };
});

const cache = new InMemoryCache();

const client = new ApolloClient({
  link: ApolloLink.from([linkError, authLink, httpLink]),
  cache,
  queryDeduplication: false,
  // onError: (e) => console.log('[Error] ApploClient ->', e),
});

export default client;
