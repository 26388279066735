import styled from 'styled-components';

export const TitleSlider = styled.h2`
  font-size: 1.25rem;
  font-weight: bold;
  letter-spacing: -1px;
  display: inline-block;
  width: 100%;
  margin: 0;
  line-height: 1;
  color: #414143;
`;

export const SliderContent = styled.div`
  justify-content: center;
  display: grid;
  margin-top: 50px;
`;

export const SliderContentItem = styled.div`
  display: inline-block;
  text-align: center;
  transition: color 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
`;

export const SliderItem = styled.a`
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:focus {
    outline: 0;
  }

  figure {
    margin: 0;
    border-radius: 15%;
    width: 80px;
    height: 80px;
    overflow: hidden;
    position: relative;
    z-index: 1;
    img {
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      z-index: 0;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
    }
  }

  span {
    text-align: center;
    margin-top: 20px;
    display: block;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.125rem;
    color: #717171;
    color: #3e3e3e;
  }

  @media only screen and (min-width: 992px) {
    cursor: pointer;
    figure {
      width: 110px;
      height: 110px;
      margin: 0 auto;
    }
  }
`;
