/* eslint-disable no-async-promise-executor */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Form } from 'react-bootstrap';
import deliveryTypes from 'data/deliveryTypes';
import { SelectCustom as Select } from './styles';

const SelectDeliveryType = ({ name, values, errors, touched, setValues }) => {
  const onChange = (selectedOptions) => {
    setValues({ ...values, [name]: selectedOptions });
  };

  return (
    <Form.Group controlId={`formBasic${name}`}>
      <Form.Label className="font-weight-bold">Modalidade de taxa de entrega</Form.Label>
      <Select
        name={name}
        defaultValue={values[name]}
        onChange={onChange}
        options={deliveryTypes}
        placeholder="Selecione uma modalidade"
        className={classnames({
          'is-invalid': errors[name] && touched[name],
        })}
      />
      <Form.Control.Feedback type="invalid">
        {errors[name] && touched[name] && errors[name]}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

SelectDeliveryType.propTypes = {
  name: PropTypes.string,
  values: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object),
  touched: PropTypes.instanceOf(Object),
  setValues: PropTypes.func,
};

export default SelectDeliveryType;
