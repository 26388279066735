/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const MessageWelcome = () => {
  return (
    <>
      <div className="bg-light mt-4">
        <Container>
          <Row className="py-4">
            <Col className="text-center">
              <p>
                <span className="font-weight-bold">Chama o Delivery</span> foi criado para ajudar as
                empresas a continuar vendendo e trabalhando mesmo diante dessa pandemia{' '}
                <small>(COVID-19)</small>.
              </p>

              <p>
                E você <span className="font-weight-bolder">consumidor</span>, compre do pequeno
                negócio e ajude a aquecer o comércio do seu bairro, cidade e região.
              </p>
              <span className="text-right font-weight-bold">
                #FiqueEmCasa #ChamaODelivery #JuntosSomosMaisFortes
              </span>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default MessageWelcome;
