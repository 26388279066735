import React from 'react';
import LogoMarca from 'assets/img/logo.svg';
import { Container } from 'react-bootstrap';
import {
  SectionFooter,
  ContentFooter,
  FooterDescription,
  LogoFooter,
  FooterInfo,
  FooterLink,
  FooterLinkExternal,
} from './styles';

const Footer = () => {
  return (
    <Container>
      <SectionFooter className="mt-5">
        <ContentFooter>
          <LogoFooter to="/">
            <img src={LogoMarca} width={55} title="Logo" alt="Logo Marca" />
          </LogoFooter>
          <FooterDescription>
            2020 - Chama O Delivery - Todos os direitos reservados.
          </FooterDescription>
          <FooterInfo href="https://bit.ly/2UyWwrC" target="_blank" title="Meu Github">
            Desenvolvido por Cleanderson Lobo
          </FooterInfo>
        </ContentFooter>
        <FooterLink to="/termos">Termos e Condições</FooterLink>
        <FooterLink to="/cadastro">Cadastre sua empresa</FooterLink>
        <FooterLinkExternal href="https://bit.ly/2Uu16HO" target="_blank">
          Instagram
        </FooterLinkExternal>
      </SectionFooter>
    </Container>
  );
};

export default Footer;
