import { init } from '@rematch/core';
import createRematchPersist from '@rematch/persist';
import * as models from './models/models';

const persistPlugin = createRematchPersist({
  key: 'root',
  whitelist: ['userModel'],
  version: 1,
});

const store = init({
  models,
  plugins: [persistPlugin],
  redux: {
    devtoolOptions: {
      disabled: true,
    },
  },
});

export default store;
