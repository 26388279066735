import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const NavHeader = styled.header`
  display: flex;
  min-height: 80px;
  background-color: #fff;
  box-shadow: inset 0 -1px 0 #dcdcdc;
  top: 0;
  z-index: 9997;
  justify-content: center;
  align-items: center;
  padding: 0;
`;

export const ContainerHeader = styled.div.attrs({
  className: 'container',
})`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: auto;
  flex-wrap: wrap;
`;

export const LogoHeader = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  a {
    border-radius: 0;
    width: 100%;
    text-align: center;
  }

  @media only screen and (max-width: 768px) {
    /* For everything bigger than 768px */
    width: 100%;
    margin-bottom: 10px;
  }
`;

export const HeaderSearch = styled.div`
  width: 100%;
  flex: 1;

  min-width: 320px;
  align-content: flex-start;
  @media only screen and (min-width: 768px) {
    /* For everything bigger than 768px */
    max-width: 450px;
  }
`;

export const HeaderLinks = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: 768px) {
    /* For everything bigger than 768px */
    width: 100%;
  }
`;

export const HeaderDelivery = styled.div``;

export const ButtonDelivery = styled.button`
  cursor: pointer;
  position: absolute;
  bottom: 11px;
  height: 16px;
  right: 0;
  background: $secondary;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: 700;
  font-size: 0.8em;
`;

export const IconLocal = styled.span`
  width: 20px;
  flex-shrink: 0;
  margin: 0 5px 0 0;
  display: flex;
`;

export const SpanTitle = styled.span`
  font-size: 0.875rem;
  height: 12px;
  color: #a6a5a5;
  text-transform: uppercase;
  margin: 0 0 5px 0;
`;

export const SpanSubtitle = styled.span`
  margin-left: 3px;
  font-size: 0.85rem;
  font-weight: 300;
  color: #3e3e3e;

  ${ButtonDelivery}:hover & {
    color: #ef4a37;
  }
`;

export const InputSearch = styled.input.attrs({
  autocomplete: false,
  ariaLabel: 'Busque por empresa ou categoria',
})`
  display: flex;
  height: 50px;
  width: 100%;
  padding-left: 63px;
  padding-right: 42px;
  border: 1px solid #f2f2f2;
  font-size: 1rem;
  line-height: 1.25em;
  font-weight: 300;
  background: #f7f7f7;
  border-radius: 4px;
  overflow: visible;
  outline: 0;
`;

export const IconLupa = styled.span`
  position: absolute;
  left: 20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 26px;
  height: 26px;
  pointer-events: none;
  display: inline-block;
`;

export const ButtonLink = styled(Link)`
  width: inherit;
  padding: 0;
  min-width: 50px;
  cursor: pointer;
  display: flex;
  margin: 0 0 0 20px;
  background: transparent;
  color: #ef4a37;
  border: none;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  font-weight: 500;
  transition: 100ms;
  height: 50px;
  border-radius: 4px;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    span {
      color: #ef4a37;
    }
  }

  &:active {
    opacity: 0.8;
  }

  &:focus {
    outline: transparent;
  }

  span {
    display: flex;
    font-size: 1rem;
    margin-left: 3px;
    color: #3e3e3e;
    font-weight: 300;
  }
`;

export const ButtonAction = styled.button`
  width: inherit;
  padding: 0;
  min-width: 50px;
  cursor: pointer;
  display: flex;
  margin: 0 0 0 20px;
  background: transparent;
  color: #ef4a37;
  border: none;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  font-weight: 500;
  transition: 100ms;
  height: 50px;
  border-radius: 4px;
  text-decoration: none;
  font-weight: bold;

  &:hover {
    text-decoration: none;
    span {
      color: #ef4a37;
    }
  }

  &:active {
    opacity: 0.8;
  }

  &:focus {
    outline: transparent;
  }

  span {
    display: flex;
    font-size: 1rem;
    margin-left: 3px;
    color: #3e3e3e;
    font-weight: 300;
  }
`;
