import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { FormCurrencyInput } from 'styles/ui';

const FormInputMaskMoney = ({ name, label, values, touched, errors, setValues, disabled }) => {
  const onChange = (value) => {
    setValues({ ...values, [name]: value });
  };
  return (
    <Form.Group controlId={`formBasic${name}`}>
      <Form.Label className="font-weight-bold">{label}</Form.Label>
      <FormCurrencyInput
        prefix="R$ "
        decimalSeparator=","
        thousandSeparator="."
        autoFocus={false}
        name={name}
        value={disabled ? '' : values[name]}
        onChange={onChange}
        className={classnames({ 'is-invalid': errors[name] && touched[name] })}
        disabled={disabled}
        isInvalid={errors[name] && touched[name] && errors[name]}
      />
      <Form.Control.Feedback type="invalid">
        {errors[name] && touched[name] && errors[name]}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

FormInputMaskMoney.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  values: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object),
  touched: PropTypes.instanceOf(Object),
  setValues: PropTypes.func,
  disabled: PropTypes.oneOfType([PropTypes.any, PropTypes.bool]),
};

export default FormInputMaskMoney;
