import React from 'react';
import classnames from 'classnames';
import LogoMarca from 'assets/img/logo.svg';
import IconLogoLight from 'assets/img/icon_logo_light.svg';
import PropTypes from 'prop-types';
import ReactLoading from 'react-loading';
import { Row, Col } from 'react-bootstrap';

const typesLogo = {
  default: LogoMarca,
  'icon-logo-light': IconLogoLight,
};

const Loading = ({
  bsClass,
  className,
  style,
  type,
  message,
  showMsg,
  messageClass,
  disabledHeight,
  showLogo,
  svgLogo,
  svgSize,
}) => (
  <div className={classnames(bsClass, className)} style={disabledHeight ? {} : style}>
    {showLogo && (
      <Row>
        <Col xs={12} className="text-center">
          <img
            src={typesLogo[svgLogo]}
            width={svgSize}
            height={svgSize}
            title="Logo"
            alt="Logo Marca"
          />
        </Col>
      </Row>
    )}
    <ReactLoading type={type} color="#ef4a37" />
    {showMsg && <p className={classnames('font-weight-bold', messageClass)}>{message}</p>}
  </div>
);

Loading.defaultProps = {
  bsClass: 'd-flex flex-column align-items-center justify-content-center',
  style: { height: '100vh' },
  disabledHeight: false,
  className: '',
  type: 'bars',
  showMsg: false,
  showLogo: true,
  svgSize: 150,
  messageClass: '',
  message: 'Aguarde...',
  svgLogo: 'default',
};

Loading.propTypes = {
  svgSize: PropTypes.number,
  disabledHeight: PropTypes.bool,
  messageClass: PropTypes.string,
  type: PropTypes.string,
  svgLogo: PropTypes.oneOf(['default', 'icon-logo-light']),
  bsClass: PropTypes.string,
  className: PropTypes.string,
  showMsg: PropTypes.bool,
  showLogo: PropTypes.bool,
  message: PropTypes.string,
  style: PropTypes.instanceOf(Object),
};

export default Loading;
