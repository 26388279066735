/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { useProgressiveImage } from 'hooks';
import { ImageCustom } from './style';

function ImageProgressive({ src, ...restProps }) {
  const srcLoad = useProgressiveImage({
    src,
    fallbackSrc: '/image/no_image.jpg',
  });
  return <ImageCustom src={srcLoad} {...restProps} />;
}

ImageProgressive.propTypes = {
  src: PropTypes.string.isRequired,
};

export default ImageProgressive;
