/* eslint-disable no-async-promise-executor */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Form } from 'react-bootstrap';
import api from 'services';
import { formatedToReactSelect, removerAcentos } from 'helpers';
import { components } from 'react-select';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { AsyncSelectCustom as AsyncSelect } from './styles';

function arrayMove(arr, from, to) {
  const array = arr.slice();
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
  return array;
}

const SortableMultiValue = SortableElement(components.MultiValue);
const SortableSelect = SortableContainer(AsyncSelect);

const MultiSelectSort = ({ name, values, errors, touched, disabled, setValues }) => {
  const [options, setOptions] = useState(null);
  const onChange = useCallback(
    (selectedOptions) => {
      setValues({ ...values, [name]: selectedOptions || [] });
    },
    [values, setValues, name]
  );

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newValue = arrayMove(values[name], oldIndex, newIndex);
    onChange(newValue);
  };

  const filterOptions = (opt, inputValue) => {
    return opt.filter((it) =>
      removerAcentos(it.label).toLowerCase().includes(removerAcentos(inputValue).toLowerCase())
    );
  };

  const getCategories = (inputValue) =>
    new Promise(async (resolve) => {
      try {
        if (options) {
          resolve(filterOptions(options, inputValue));
        } else {
          const res = await api.categories.get();
          const opts = formatedToReactSelect(res);
          setOptions(opts);
          resolve(filterOptions(opts, inputValue));
        }
      } catch (error) {
        resolve();
      }
    });

  const promiseOptions = (newValue) => {
    const inputValue = newValue.replace(/\W/g, '');
    return Promise.resolve(getCategories(inputValue));
  };

  return (
    <Form.Group controlId={`formBasic${name}`}>
      <Form.Label className="font-weight-bold">Categorias</Form.Label>

      <Form.Text className="text-muted pb-2" style={{ fontSize: '0.85rem' }}>
        Escolha até 3 categorias que representam sua empresa para ajudar as pessoas a encontrá-la.
      </Form.Text>
      <SortableSelect
        // react-sortable-hoc props:
        axis="xy"
        name={name}
        onSortEnd={onSortEnd}
        distance={4}
        // small fix for https://github.com/clauderic/react-sortable-hoc/pull/352:
        getHelperDimensions={({ node }) => node.getBoundingClientRect()}
        // react-select props:
        isMulti
        cacheOptions
        defaultOptions
        loadOptions={promiseOptions}
        value={values[name]}
        isDisabled={disabled}
        onChange={onChange}
        components={{
          MultiValue: SortableMultiValue,
        }}
        closeMenuOnSelect={values[name].length >= 2}
        lockToContainerEdges
        className={classnames({
          'is-invalid': errors[name] && touched[name] && errors[name],
        })}
        placeholder="Selecione categorias"
        noOptionsMessage={() => 'Sem dados'}
      />
      <Form.Control.Feedback type="invalid">
        {errors[name] && touched[name] && errors[name]}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

MultiSelectSort.propTypes = {
  name: PropTypes.string,
  values: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object),
  touched: PropTypes.instanceOf(Object),
  disabled: PropTypes.bool,
  setValues: PropTypes.func,
};

export default MultiSelectSort;
