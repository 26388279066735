import api, { handleApiErros } from 'services';

const { REACT_APP_LOCAL_STORAGE } = process.env;

export const INITIAL_STATE = {
  isFetching: false,
  error: false,
  message: null,
};

export const authModel = {
  state: INITIAL_STATE,
  reducers: {
    fetchRequest: (state) => ({
      ...state,
      isFetching: true,
      error: false,
      code: 0,
      message: null,
    }),
    successRequest: (state, props) => ({
      ...state,
      isFetching: false,
      error: false,
      message: null,
      ...props,
    }),
    failureRequest: (state, props) => ({
      ...state,
      isFetching: false,
      error: true,
      ...props,
    }),
    failureRegister: (state, props) => ({
      ...state,
      isFetching: false,
      error: true,
      ...handleApiErros(props),
    }),
    hideAlert: (state) => ({
      ...state,
      error: false,
      message: null,
    }),
    clear: () => INITIAL_STATE,
  },
  effects: (dispatch) => ({
    async login(values) {
      dispatch.authModel.fetchRequest();
      try {
        const response = await api.authLogin(values);
        if (response.status !== 200 || !response.data) throw response;
        const { data } = response;
        localStorage.setItem(REACT_APP_LOCAL_STORAGE, data.jwt);
        dispatch.userModel.authSuccess(data);
        dispatch.authModel.successRequest();
      } catch (error) {
        localStorage.removeItem(REACT_APP_LOCAL_STORAGE);
        dispatch.authModel.failureRequest(handleApiErros(error));
      }
    },
    async registerCompany(response) {
      const { data } = response;
      localStorage.setItem(REACT_APP_LOCAL_STORAGE, data.jwt);
      dispatch.userModel.authSuccess(data);
      dispatch.authModel.successRequest();
    },
    signOut() {
      localStorage.removeItem(REACT_APP_LOCAL_STORAGE);
      // limpar filtros e dados das redações;
      dispatch.authModel.logout();
    },
  }),
};
