import axios from 'axios';
import store from 'store/store';
import { MESSAGES_API } from './util';

const { REACT_APP_URL_API, REACT_APP_LOCAL_STORAGE } = process.env;

export const ENDPOINTS_PODIO = {
  login: '/auth/local',
  user: '/users',
  register: '/auth/local/register',
  categories: '/categories',
  cities: '/cities',
  upload: '/upload',
  companies: '/companies',
};

export const axiosInstance = axios.create({
  baseURL: REACT_APP_URL_API,
  timeout: 90000,
});

axiosInstance.interceptors.request.use((config) => {
  try {
    const token = localStorage.getItem(REACT_APP_LOCAL_STORAGE);
    if (token) {
      return {
        ...config,
        headers: {
          'Content-Type': 'application/json',
          ...config.headers,
          Authorization: `Bearer ${token}`,
        },
      };
    }
    return config;
  } catch (error) {
    return config;
  }
});

axiosInstance.interceptors.response.use(
  (config) => config,
  (error) => {
    if (
      error.response &&
      Object.prototype.hasOwnProperty.call(error.response, 'status') &&
      error.response.status === 401
    ) {
      store.dispatch.userModel.signOut();
    }
    return error.response;
  }
);

export const handleApiErros = (response) => {
  try {
    if (
      !response ||
      (response && (!response.data || (response.data && !response.data.message.length)))
    ) {
      throw response;
    }
    const { data } = response;
    const { messages } = data.message[0];
    const [error] = messages;
    if (!MESSAGES_API[error.id]) throw error;
    return { message: MESSAGES_API[error.id] };
  } catch (error) {
    return { message: MESSAGES_API['Error.custom'] };
  }
};

export const handleFormErros = (response) => {
  try {
    if (
      !response ||
      (response && (!response.data || (response.data && !response.data.message.length)))
    ) {
      throw response;
    }
    const { data } = response;
    const { messages } = data.message[0];
    const newMessages = messages.reduce((il, error) => {
      if (error.custom) return [...il, error];
      if (!MESSAGES_API[error.id]) return [...il, { message: MESSAGES_API['Error.custom'] }];
      return [...il, { message: MESSAGES_API[error.id] }];
    }, []);

    return newMessages;
  } catch (error) {
    return [{ message: MESSAGES_API['Form.custom'] }];
  }
};

const api = {
  authLogin: (variables) => axiosInstance.post(ENDPOINTS_PODIO.login, variables),
  register: (variables) => axiosInstance.post(ENDPOINTS_PODIO.register, variables),
  user: {
    me: () => axiosInstance.get(`${ENDPOINTS_PODIO.user}/me`),
    get: (user) => axiosInstance.get(`${ENDPOINTS_PODIO.user}/${user.id}`),
    update: ({ user, ...variables }) =>
      axiosInstance.put(`${ENDPOINTS_PODIO.user}/${user.id}`, variables),
  },
  categories: {
    get: (variables = { _sort: 'name' }) =>
      axiosInstance.get(ENDPOINTS_PODIO.categories, { params: variables }),
  },
  cities: {
    get: (variables = { _sort: 'name' }) =>
      axiosInstance.get(ENDPOINTS_PODIO.cities, { params: variables }),
  },
  companies: {
    post: (variables = {}, config = {}) =>
      axiosInstance.post(ENDPOINTS_PODIO.companies, variables, config),
    postImage: ({ onUploadProgress = () => {}, ...restProps }) => {
      const body = new FormData();
      body.append('data', JSON.stringify(restProps));
      return axiosInstance({
        url: ENDPOINTS_PODIO.companies,
        method: 'POST',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: body,
        onUploadProgress,
      });
    },
    put: (company, ...variables) =>
      axiosInstance.put(`${ENDPOINTS_PODIO.companies}/${company.id}`, variables || {}),
  },
  upload: {
    post: ({ onUploadProgress = () => {}, ...restProps }) => {
      const body = new FormData();
      Object.keys(restProps).forEach((key) => {
        // files, refId, ref,field,
        body.append(key, restProps[key]);
      });
      return axiosInstance({
        url: ENDPOINTS_PODIO.upload,
        method: 'POST',
        headers: {},
        data: body,
        onUploadProgress,
      });
    },
  },
};
export default api;
