/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Footer } from 'components';
import { Container } from 'react-bootstrap';

const ContainerLayout = ({ children, disableContainer, ...restProps }) => {
  return (
    <>
      {disableContainer ? children : <Container {...restProps}>{children}</Container>}
      <Footer />
    </>
  );
};

ContainerLayout.defaultProps = {
  disableContainer: false,
};

ContainerLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.any]),
  disableContainer: PropTypes.bool,
};

export default ContainerLayout;
