/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FormControl, InputGroupPrepend } from 'styles/ui';
import { Form, InputGroup } from 'react-bootstrap';
import { get } from 'lodash';

const getValues = (object, name) => {
  try {
    if (name.split('.').length <= 1) return object[name];
    return get(object, name);
  } catch (error) {
    return object[name];
  }
};

const InputFormGroup = ({
  label,
  name,
  values,
  errors,
  touched,
  disableFocus = false,
  children,
  prependComponent = null,
  formText = null,
  isOptinal = false,
  ...restForm
}) => {
  const [showInfoText, setShowInfo] = useState(disableFocus);
  function handleOnFocus() {
    if (!disableFocus) setShowInfo((val) => !val);
  }
  const isInvalid = getValues(errors, name) && getValues(touched, name);
  return (
    <Form.Group controlId={`formBasic${name}`}>
      <Form.Label className="font-weight-bold">
        {label}
        {isOptinal && <small className="text-muted">(opcional)</small>}
      </Form.Label>
      {showInfoText && children}
      {formText && (
        <Form.Text className="text-muted pb-2" style={{ fontSize: '0.85rem' }}>
          {formText}
        </Form.Text>
      )}
      <InputGroup>
        <InputGroupPrepend>
          <InputGroup.Text>{prependComponent || null}</InputGroup.Text>
        </InputGroupPrepend>
        <FormControl
          name={name}
          onFocus={handleOnFocus}
          isInvalid={isInvalid}
          value={getValues(values, name)}
          {...restForm}
        />
      </InputGroup>
      <Form.Control.Feedback type="invalid" className={classnames({ 'd-block': isInvalid })}>
        {getValues(errors, name) && getValues(touched, name) && getValues(errors, name)}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

InputFormGroup.propTypes = {
  isOptinal: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  disableFocus: PropTypes.bool,
  values: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object),
  touched: PropTypes.instanceOf(Object),
  children: PropTypes.any,
  formText: PropTypes.string,
  prependComponent: PropTypes.oneOfType([
    PropTypes.any,
    PropTypes.element,
    PropTypes.instanceOf(Array),
  ]),
};

export default InputFormGroup;
