export const INITIAL_STATE = {
  isAuthenticated: false,
  user: null,
  jwt: null,
};

export const userModel = {
  state: INITIAL_STATE,
  reducers: {
    authSuccess: (state, props) => ({
      ...state,
      isAuthenticated: true,
      ...props,
    }),
    updateUser: (state, props) => ({
      ...state,
      ...props,
    }),
    logout: () => INITIAL_STATE,
  },
  effects: (dispatch) => ({
    signOut() {
      localStorage.clear();
      // limpar filtros e dados das redações;
      dispatch.userModel.logout();
      dispatch.authModel.clear();
    },
    signOutError() {
      localStorage.clear();
      // limpar filtros e dados das redações;
      dispatch.userModel.logout();
      dispatch.authModel.failureRequest({
        message: 'Realize o login novamente',
      });
    },
  }),
};
