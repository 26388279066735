import api, { handleFormErros } from 'services';
import { formartSelectValues } from './utils';

export const INITIAL_STATE = {
  isFetching: false,
  status: [], // ['created', 'upload', 'finish']
  error: false,
  messages: null,
  stepMessage: 'Aguarde...',
};

export const registerModel = {
  state: INITIAL_STATE,
  reducers: {
    request: (state) => ({
      ...state,
      isFetching: true,
      error: false,
      messages: null,
      status: [],
      stepMessage: 'Aguarde...',
    }),
    successRegister: (state, props) => ({
      ...state,
      status: ['created'],
      isFetching: true,
      ...props,
    }),
    successRequest: (state, status) => ({
      ...state,
      status: [...state.status, status],
    }),
    fetchRequest: (state, props) => ({
      ...state,
      isFetching: true,
      ...props,
    }),
    finnalyRegister: (state, props) => ({
      ...state,
      status: [...state.status, 'finish'],
      isFetching: false,
      ...props,
    }),
    failure: (state, props) => ({
      ...state,
      isFetching: false,
      error: true,
      ...props,
    }),
    hideAlert: (state) => ({
      ...state,
      error: false,
    }),
    clear: () => INITIAL_STATE,
  },
  effects: (dispatch) => ({
    async registerCompany(values) {
      try {
        dispatch.registerModel.request();
        const {
          // required
          name,
          slug,
          categories: categoriesSelect, // select
          neighborhood,
          city: citySelect, // select
          deliveryType: deliveryTypeSelect, // select
          deliveryPrice, // dependes - deliveryType == fixed
          deliveryTime,
          picture,
          shortDescription,
          // only one number
          whatsapp,
          // fixed
          state,
          stateSort,
          // not requeried
          description,
          paymentTypes: paymentTypesObject,
          email,
          instagram,
          phone,
          address,
          postalCode,
          finalyCadastro,
        } = values;
        // formatar valores do react-select
        const paymentTypes = Object.keys(paymentTypesObject || {}).filter(
          (key) => paymentTypesObject[key]
        );
        const { categories, deliveryType, city } = formartSelectValues({
          categories: categoriesSelect,
          deliveryType: deliveryTypeSelect,
          city: citySelect,
        });
        const companyValues = {
          name,
          slug,
          shortDescription: shortDescription.replace(/(\r\n|\n|\r)/gm, ''),
          description: description.replace(/(\r\n|\n|\r)/gm, ''),
          neighborhood,
          categories,
          deliveryType,
          city,
          paymentTypes,
          deliveryPrice: !deliveryType.includes('fixed') ? '' : deliveryPrice,
          email,
          instagram,
          phone,
          address,
          postalCode,
          state,
          stateSort,
          whatsapp,
          deliveryTime,
        };
        // criar empresa
        const response = await api.companies.post(companyValues);
        if (response.status !== 200 || !response.data) throw response;
        const { data } = response;
        if (!picture) {
          dispatch.registerModel.finnalyRegister();
          finalyCadastro();
        } else {
          dispatch.registerModel.fetchRequest({
            stepMessage: 'Enviando imagem...',
          });
          await api.upload.post({
            files: picture.file,
            refId: data.id,
            ref: 'company',
            field: 'picture',
          });
          dispatch.registerModel.finnalyRegister();
          finalyCadastro();
        }
      } catch (error) {
        dispatch.registerModel.failure({ messages: handleFormErros(error) });
      }
    },
  }),
};
