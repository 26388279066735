import styled from 'styled-components';
import Select from 'react-select';

export const SelectCustom = styled(Select).attrs({
  classNamePrefix: 'Select-container',
})`
  &.is-invalid {
    border: 1px solid #dc3545;
    border-radius: 4px;
    background-image: url(data:image/svg+xml,%3csvg xmlns=http://www.w3.org/2000/svg width=12 hei…circle cx=6 cy=8.2 r=.6 fill=%23dc3545 stroke=none/%3e%3c/svg%3e);
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }
  .Select-container {
    &__control {
      min-height: 50px;
      &__value-container {
        min-height: 50px;
      }
    }
  }
`;
