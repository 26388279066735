/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormControl } from 'styles/ui';
import { Form } from 'react-bootstrap';
import classnames from 'classnames';

const InputForm = ({
  label,
  name,
  type = 'text',
  values,
  errors,
  touched,
  disableFocus = false,
  children,
  formText = null,
  isOptinal = false,
  onBlur,
  ...restForm
}) => {
  const [showInfoText, setShowInfo] = useState(disableFocus);
  function handleOnFocus() {
    if (!disableFocus) setShowInfo((val) => !val);
    if (onBlur) onBlur(values[name]);
  }

  return (
    <Form.Group controlId={`formBasic${name}`}>
      <Form.Label className="font-weight-bold">
        {label}
        {isOptinal && <small className="text-muted">(opcional)</small>}
      </Form.Label>
      {showInfoText && children}
      {formText && (
        <Form.Text className="text-muted pb-2" style={{ fontSize: '0.85rem' }}>
          {formText}
        </Form.Text>
      )}
      <FormControl
        type={type}
        name={name}
        onFocus={handleOnFocus}
        onBlur={handleOnFocus}
        isInvalid={errors[name] && touched[name]}
        value={values[name]}
        {...restForm}
      />
      <Form.Control.Feedback
        type="invalid"
        className={classnames({ 'd-block': errors[name] && touched[name] })}>
        {errors[name] && touched[name] && errors[name]}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

InputForm.propTypes = {
  isOptinal: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  disableFocus: PropTypes.bool,
  values: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object),
  touched: PropTypes.instanceOf(Object),
  children: PropTypes.any,
  onBlur: PropTypes.func,
  formText: PropTypes.string,
};

export default InputForm;
