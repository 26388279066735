import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Alert } from 'react-bootstrap';

const AuthAlert = ({ error, message }) => {
  const dispatch = useDispatch();
  function handleOnClose() {
    dispatch.authModel.hideAlert();
  }
  if (!error) return null;
  return (
    <Alert variant="danger" onClose={handleOnClose} dismissible>
      <p className="text-center t-16 m-0">{message}</p>
    </Alert>
  );
};

AuthAlert.propTypes = {
  error: PropTypes.bool,
  message: PropTypes.string,
};

export default AuthAlert;
