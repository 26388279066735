import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Carousel from 'react-multi-carousel';
import { CATEGORIAS_SLIDER } from 'helpers';
import { TitleSlider, SliderItem, SliderContent, SliderContentItem } from './styles';

const responsive = {
  desktop: {
    breakpoint: { max: 3900, min: 992 },
    items: 7,
    partialVisibilityGutter: 96, // this is needed to tell the amount of px that should be visible.
  },
  tablet: {
    breakpoint: { max: 992, min: 778 },
    items: 5,
    partialVisibilityGutter: 60, // this is needed to tell the amount of px that should be visible.
  },
  mobilesm: {
    breakpoint: { max: 778, min: 576 },
    items: 3,
    partialVisibilityGutter: 48, // this is needed to tell the amount of px that should be visible.
  },
  mobile: {
    breakpoint: { max: 576, min: 0 },
    items: 3,
    partialVisibilityGutter: 32, // this is needed to tell the amount of px that should be visible.
  },
};

const CategoriesCarousel = () => {
  return (
    <section className="flex-container noselect">
      <Row>
        <Col xs={12}>
          <TitleSlider>Categorias</TitleSlider>
        </Col>
      </Row>
      <Carousel
        responsive={responsive}
        centerMode
        infinite
        dotListClass="custom-dot-list-style"
        removeArrowOnDeviceType={['mobile', 'mobilesm', 'tablet']}
        customTransition="all .2"
        transitionDuration={100}>
        {CATEGORIAS_SLIDER.map((categoria) => (
          <SliderContent key={categoria.nome}>
            <SliderContentItem>
              <SliderItem key={categoria.nome}>
                <figure>
                  <img
                    src={`image/categories/${categoria.img}`}
                    alt={categoria.nome}
                    draggable="false"
                  />
                </figure>
                <span>{categoria.nome}</span>
              </SliderItem>
            </SliderContentItem>
          </SliderContent>
        ))}
      </Carousel>
    </section>
  );
};

export default CategoriesCarousel;
