/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

const PrivateRoute = ({ children, isPrivate, redirectTo, redirectFrom, ...restProps }) => {
  const { isAuthenticated, user } = useSelector((state) => state.userModel);
  return (
    <Route
      {...restProps}
      render={({ location }) =>
        (isAuthenticated && user) || !isPrivate ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: redirectTo || '/',
              state: { from: redirectFrom || location },
            }}
          />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  children: PropTypes.oneOfType([PropTypes.any, PropTypes.func, PropTypes.instanceOf(Element)]),
  isPrivate: PropTypes.bool,
  redirectTo: PropTypes.string,
  redirectFrom: PropTypes.string,
};

export default PrivateRoute;
