import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const HtmlHeadCompany = ({ name, city, picture }) => {
  const description = `Faça seu pedido no ${name} aqui no Chama O Delivery, o jeito mais fácil de comprar de empresas que trabalham com delivery na região, através da internet e whatsapp com custo zero.`;
  const title = `${name}, ${city.name}/${city.state.code} - Chama O Delivery`;
  const image =
    picture && picture.url ? picture.url : 'https://chamaodelivery.com/image/chamaodelivery.jpg';
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta name="description" content={description} />
      <meta name="keywords" content={title} />
      <meta name="author" content="Chama O Delivery" />

      {/* <!-- Google / Search Engine Tags --> */}
      <meta itemProp="name" content={title} />
      <meta itemProp="description" content={description} />
      <meta itemProp="image" content={image} />

      {/* <!-- Open Graph / Facebook --> */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={window.localStorage.href || 'https://chamaodelivery.com/'} />
      <meta property="og:title" content="Evite sair de casa, Chama O Delivery" />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:image:type" content="image/jpg" />
      <meta property="og:image:width" content="800" />
      <meta property="og:image:height" content="800" />

      {/* <!-- WhatsApp --> */}
      <meta property="og:image" itemProp="image" content={image} />
      <meta property="og:image:type" content="image/jpg" />
      <meta property="og:image:width" content="512" />
      <meta property="og:image:height" content="512" />

      {/* <!-- Twitter --> */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta
        property="twitter:url"
        content={window.localStorage.href || 'https://chamaodelivery.com/'}
      />
      <meta property="twitter:title" content="Evite sair de casa, Chama O Delivery" />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={image} />
    </Helmet>
  );
};

HtmlHeadCompany.propTypes = {
  name: PropTypes.string,
  city: PropTypes.shape({
    name: PropTypes.string,
    state: PropTypes.shape({
      code: PropTypes.string,
    }),
  }),
  picture: PropTypes.oneOfType([
    PropTypes.any,
    PropTypes.shape({
      url: PropTypes.string,
    }),
  ]),
};

export default HtmlHeadCompany;
