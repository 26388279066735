import React from 'react';
import { Form } from 'react-bootstrap';
import LogoSertao from 'assets/img/logo.svg';
import Lupa from 'assets/img/lupa.svg';
import { useSelector, useDispatch } from 'react-redux';
// import Localization from 'assets/img/localization.svg';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import {
  InputSearch,
  HeaderSearch,
  ContainerHeader,
  NavHeader,
  HeaderLinks,
  LogoHeader,
  IconLupa,
  // HeaderDelivery,
  // SpanTitle,
  // SpanSubtitle,
  // ButtonDelivery,
  // IconLocal,
  ButtonLink,
  ButtonAction,
} from './styles';

const Header = () => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.userModel);

  function handleLogout() {
    Swal.fire({
      title: 'Sair',
      text: 'Tem certeza que deseja sair da sua conta?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#ef4a37',
      cancelButtonColor: '#313131',
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        dispatch.userModel.signOut();
      }
    });
  }

  return (
    <NavHeader className="py-3 py-lg-0">
      <ContainerHeader>
        <LogoHeader>
          <Link to="/" aria-label="Ir para Home" aria-hidden="false">
            <img src={LogoSertao} width={120} title="Logo" alt="Logo Marca" />
          </Link>
        </LogoHeader>
        <HeaderSearch>
          <Form inline className="position-relative w-100">
            <InputSearch
              type="search"
              placeholder="Busque por empresa ou categoria"
              className="w-100"
            />
            <IconLupa>
              <img src={Lupa} width={28} title="lupa" alt="Logo Marca" />
            </IconLupa>
          </Form>
        </HeaderSearch>
        {/* <HeaderDelivery>
          <SpanTitle>Buscar em</SpanTitle>
          <ButtonDelivery>
            <IconLocal>
              <img src={Localization} width={16} title="lupa" alt="Logo Marca" />
            </IconLocal>
            <SpanSubtitle>Minha Localização</SpanSubtitle>
          </ButtonDelivery>
        </HeaderDelivery> */}
        <HeaderLinks>
          {isAuthenticated ? (
            <>
              <ButtonLink to="/empresa">
                <span>Minha Empresa</span>
              </ButtonLink>
              <ButtonAction onClick={handleLogout}>Sair</ButtonAction>
            </>
          ) : (
            <>
              <ButtonLink to="/cadastro">
                <span>Cadaste sua Empresa</span>
              </ButtonLink>
              <ButtonLink to="/empresa">
                <span>Já tenho cadastro</span>
              </ButtonLink>
            </>
          )}
        </HeaderLinks>
      </ContainerHeader>
    </NavHeader>
  );
};

export default Header;
