/* eslint-disable no-extend-native */
export const CATEGORIAS = [
  'Açaí',
  'Comida Árabe',
  'Comida Asiática',
  'Bebidas',
  'Comida Brasileira',
  'Hambúrguer',
  'Cafeteria',
  'Carnes',
  'Comida Chinesa',
  'Congelados',
  'Comida Contemporanea',
  'Comida Regional',
  'Coreana',
  'Cozinha Rápida',
  'Casa de Sucos',
  'Conveniência',
  'Doces e Bolos',
  'Espanhola',
  'Frangos',
  'Comida Francesa',
  'Frutos do Mar',
  'Comida Italiana',
  'Comida Japonesa',
  'Lanches',
  'Marmita',
  'Mercado',
  'Nordestina',
  'Padaria',
  'Pastel',
  'Peruana',
  'Pizza',
  'Comida Portuguesa',
  'Peixes',
  'Salgados',
  'Comida Saudável',
  'Sorvetes',
  'Sopas & Caldos',
  'Tapioca',
  'Comida Variada',
  'Vegana',
  'Comida Vegetariana',
  'Farmácia',
  'Roupas',
  'Perfume',
  'Gás',
  'Auto-Peça',
  'Buffet',
  'Clube',
];

export const CATEGORIAS_SLIDER = [
  { nome: 'Mercado', img: 'SP-mercado.png' },
  { nome: 'Infantil', img: '19C1-toys.jpg' },
  { nome: 'Saúde', img: 'farmacia.png' },
  { nome: 'Restaurante', img: '19C1-brasileira-v2.jpg' },
  { nome: 'Moda e Beleza', img: '19C1-beleza.jpg' },
  { nome: 'Serviços', img: '19C1-services.jpg' },
  { nome: 'Lanches', img: '19C1-lanches-v2.jpg' },
  { nome: 'Utilidades', img: '19C1-utilites.jpg' },
  { nome: 'Animais', img: '19C1-pets.jpg' },

  { nome: 'Diversos', img: '19C1-diversos.jpg' },
];

export const formatedSlug = (value) => value.toLowerCase().replace(/[^a-zA-Z0-9]+/g, '-');

export const formatedToReactSelect = (res) => {
  try {
    if (res.status !== 200 || !res.data) throw res;
    return res.data.map((item) => ({
      ...item,
      label: item.name,
      value: item.id,
    }));
  } catch (error) {
    return [];
  }
};

export const formatDataForReactSelect = (array) => {
  try {
    return array.map((item) => ({
      ...item,
      label: item.name,
      value: item.id,
    }));
  } catch (error) {
    return [];
  }
};

export const generateUid = () => {
  const s3 = () =>
    Math.floor((1 + Math.random()) * new Date().getTime())
      .toString(16)
      .substring(1);
  return `${s3(0x1000000000000000)}-${s3(0x1000000)}-${s3(0x1000000)}`;
};

export const generateWhastappField = (isDefault = false) => {
  return {
    __component: 'company.whatsapp',
    cellphone: '',
    link: '',
    isDefault,
  };
};

export const removerAcentos = (s) =>
  s.normalize('NFD').replace(/[\u0300-\u036f|\u00b4|\u0060|\u005e|\u007e]/g, '');

export const stringToSlug = (name) => formatedSlug(removerAcentos(name));

export const cityToSlug = ({ name }) => formatedSlug(removerAcentos(`${name} ce`));
