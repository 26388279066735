import styled from 'styled-components';

export const CardCompany = styled.a`
  border: 1px solid #f2f2f2;
  display: grid;
  transition: 200ms;
  height: 110px;
  padding: 12px;
  border-radius: 4px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05);
  text-decoration: none;
  width: 100%;
  grid-template-columns: 60px 1px 1fr;
  grid-template-rows: 1fr 20px;
  grid-template-areas:
    'figure divider title'
    'figure divider content';
  grid-column-gap: 12px;
  &:hover {
    text-decoration: none;
  }
  text-decoration: none;
  &::before {
    grid-area: divider;
    content: '';
    width: 1px;
    background-color: #f2f2f2;
  }
  @media only screen and (min-width: 992px) {
    height: 130px;
    grid-template-columns: 85px 1px 1fr;
    grid-column-gap: 20px;
    padding: 20px;
    cursor: pointer;
  }
`;

export const CardFigure = styled.figure`
  grid-area: figure;
  overflow: hidden;
  position: relative;
  height: 100%;
  border-radius: 4px 0 0 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 54px;
    height: 54px;
    background: #f2f2f2;
    border-radius: 4px;
    transition: 200ms;
    object-fit: cover;
    @media only screen and (min-width: 992px) {
      width: 85px;
      height: 85px;
    }
  }
`;

export const CardTitle = styled.h3`
  grid-area: title;
  margin: 0;
  padding: 0;
`;

export const CardHeader = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  position: relative;
  padding: 0 0 2px;
  @media only screen and (min-width: 992px) {
    padding: 0 0 8px;
  }
`;

export const CardInfo = styled.div`
  width: 100%;
  display: flex;
  color: #717171;
  font-size: 0.875rem;
  line-height: 0.9375rem;
  padding: 0 0 2px;
  flex-wrap: wrap;
  @media only screen and (min-width: 992px) {
    font-size: 0.875rem;
    line-height: 1.0625rem;
  }
  .text-yellow {
    color: #e7a74e;
  }

  .category {
    font-weight: bold;
    line-height: 9px;
    @media only screen and (min-width: 992px) {
      font-size: 0.875rem;
    }
  }
`;

export const CardCompanyName = styled.span`
  color: #3e3e3e;
  font-size: 0.875rem;
  line-height: 0.875rem;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  height: 18px;

  @media only screen and (min-width: 992px) {
    font-size: 1rem;
    line-height: 1rem;
  }
`;

export const CardFooter = styled.div`
  margin: 0;
  width: 100%;
  display: flex;
  align-items: flex-end;
  font-weight: 300;
  color: #717171;
  font-size: 0.875rem;
  line-height: 0.9375rem;
  @media only screen and (min-width: 992px) {
    font-size: 0.825rem;
    line-height: 1.0625rem;
  }
`;

export const CardStatus = styled.div`
  position: absolute;
  margin: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  line-height: 1.25rem;
  color: #fff;
  border-radius: 4px;
  width: 54px;
  height: 54px;
  @media only screen and (min-width: 992px) {
    font-size: 1rem;
    width: 85px;
    height: 85px;
  }
`;
